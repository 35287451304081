import axios from "axios";
import http from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const apiTokenUrls = process.env.REACT_APP_API_TOKEN_URL;

axios.defaults.baseURL = apiUrl;
axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
let token = localStorage.getItem("XSRF-TOKEN");

if (token) {
  axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
} else {
  // getCsrf();
}

export const getCsrf = (__) => {
  http
    .get(apiTokenUrls, { withCredentials: true })
    .then((res) => {});
};

/*
|======================================================
| Authentication
|======================================================
*/
export const login = (data) => axios.post("/login", data);

/*
|======================================================
| Daily Figures
|======================================================
*/
export const getDailyFigures = (filter = {}, type) => {
  return axios.get("/daily-figure", {
    params: filter,
  });
};

export const getDailyFigureById = (id = {}) => {
  return axios.get(`/daily-figure/${id}`);
};

export const saveDailyFigure = (data) => {
  return axios.post(`/daily-figure`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateDailyFigure = (id, data) => {
  return axios.post(`/daily-figure/${id}`, data, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

export const fetchWebsites = () => {
  return axios.get("/daily-figure/websites");
};

export const fetchAccounts = () => {
  return axios.get("/daily-figure/accounts");
};

export const getAccounts = () => {
  return axios.get("/daily-figure/get-websites");
};

export const getUserNames = () => {
  return axios.get("/daily-figure/get-accounts");
};

/*
|======================================================
| Handicappers
|======================================================
*/
export const getHandicappers = (is_active) => {
  return axios.get(`/handicapper?is_active=${is_active}`);
};

export const getActiveHandicappers = () => {
  return axios.get("/handicapper?is_active=1");
};

export const saveHandicapper = (data) => {
  return axios.post("/handicapper", data);
};

export const updateHandicapper = (data, id) => {
  return axios.put(`/handicapper/${id}`, data);
};

export const fetchHandicapperInsights = (week, account_id) => {
  return axios.get(
    `/handicapper/insights?week=${week}${
      account_id ? "&account_id=" + account_id : ""
    }`
  );
};

/*
|======================================================
| Account
|======================================================
*/
export const getBetsAccounts = () => {
  return axios.get(`/bet-accounts`);
};

export const addAccount = (data) => {
  return axios.post("/bet-accounts", data);
};
