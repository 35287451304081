import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  handicappers: [],
};

const handicapperSlice = createSlice({
  name: "handicappers",
  initialState: initialState,
  reducers: {
    setHandicappers: (state, action) => {
      state.handicappers = action.payload;
    },
  },
});

export const { setHandicappers } = handicapperSlice.actions;

export default handicapperSlice.reducer;
