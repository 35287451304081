import Input from "../components/form/Input";
import { ReactComponent as LoginIcon } from "../assets/icons/login.svg";
import Button from "../components/form/Button";
import { useNavigate } from "react-router-dom";
import { createRef, useEffect, useState } from "react";
import { validateEmail } from "../utils/helpers";
import { login } from "../routes/api";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoggedIn, setToken } from "../redux/slices/auth";

export default function Login() {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [errors, setErrors] = useState({
    general: null,
    email: null,
    password: null,
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const email = createRef();
  const password = createRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) navigate("/auth/dashboard/daily-figures", { replace: true });
  }, []);

  const handleSubmit = () => {
    let error = errors;
    let is_error = false;
    if (email.current?.value == "") {
      error = { ...error, email: "Email field is required" };
      is_error = true;
    } else if (validateEmail(email.current?.value) == null) {
      error = { ...error, email: "Please enter correct email" };
      is_error = true;
    }

    if (password.current?.value == "") {
      error = { ...error, password: "Password field is required" };
      is_error = true;
    }

    if (is_error) {
      setErrors(error);
      return;
    }
    setIsSubmit(true);
    login({
      email: email.current?.value,
      password: password.current?.value,
    }).then((res) => {
      if (res.data?.status == "error") {
        error = { ...error, general: res.data?.message };
        setErrors(error);
        setIsSubmit(false);
      } else {
        localStorage.setItem("token", res?.data?.data?.token);
        localStorage.setItem("isLoggedIn", true);

        dispatch(setToken(res?.data?.data?.token));
        dispatch(setIsLoggedIn(true));
        navigate("/auth/dashboard/daily-figures", { replace: true });
      }
    });
  };

  return (
    <div className="grid grid-cols-6">
      <div className="col-span-2 flex flex-col gap-4 justify-center items-center">
        <h4 className="text-4xl">Welcome Back</h4>
        {errors.general && (
          <span className="text-red-500">{errors.general}</span>
        )}
        <div className="w-8/12">
          <Input
            ref={email}
            errors={errors.email}
            placeholder="Enter Email"
            label="Email"
            type="email"
          />
        </div>
        <div className="w-8/12">
          <Input
            ref={password}
            errors={errors.password}
            placeholder="Enter Password"
            type="password"
            label="Email"
          />
        </div>
        <div className="w-8/12">
          <Button
            innerText="Login"
            classes="bg-green-400 text-white"
            handleSubmit={handleSubmit}
            isLoading={isSubmit}
          />
        </div>
      </div>
      <div className="flex justify-center col-span-4 bg-primary h-screen">
        <LoginIcon className="w-8/12" />
      </div>
    </div>
  );
}
