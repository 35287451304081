import React, { useEffect, useState } from "react";
import { getBetsAccounts, getHandicappers, updateHandicapper } from "../../../routes/api";
import AddAccount from "./AddAccount";
import Modal from "../../../components/Modal";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChartLine,
    faCheck,
    faEye,
    faPlus,
    faRepeat,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useTransition } from "react";
import { useNavigate } from "react-router-dom";

function Accounts() {
    const [accounts, setAccounts] = useState([]);
    const [showAddModal, setAddModal] = useState(false);
    const [editMode, setEditMode] = useState(-1);
    const [activeStatus, setActiveStatus] = useState(null);
    const [updateId, setUpdateId] = useState(null);
    const [status, setStatus] = useState("1");
    const [handicapperName, setHandicapperName] = useState(null);
    const [isPending, startTransition] = useTransition();

    const navigate = useNavigate();

    useEffect(() => {
        fetchBetsAccounts();
    }, [showAddModal]);

    const activateEditMode = (name, active_status, id, index) => {
        setHandicapperName(name);
        setActiveStatus(active_status);
        setUpdateId(id);
        setEditMode(index);
    };

    const updateHandicapper = () => {
        updateHandicapper(
            { title: handicapperName, is_active: activeStatus },
            updateId
        ).then((res) => {
            if (res?.data?.status == "success") {
                setEditMode(-1);
                startTransition(() => {
                    getBetsAccounts(status);
                });
            }
        });
    };

    const fetchBetsAccounts = (status) => {
        getBetsAccounts().then((res) => {
            setAccounts(res?.data);
        });
    };

    return (
        <>
            <div className="flex justify-between items-end">
                <h1 className="text-lg font-bold">Accounts</h1>
                <div>
                    <button
                        onClick={() => setAddModal(true)}
                        className="bg-primary text-white rounded p-2 mr-2 text-bold"
                    >
                        <FontAwesomeIcon className="mr-1" icon={faPlus} />
                        Add Account
                    </button>
                    {/* <button
            onClick={() => navigate('insights')}
            className="bg-gray-500 text-white rounded p-2 text-bold"
          >
            <FontAwesomeIcon className="mr-1" icon={faChartLine} />
            Insights
          </button> */}
                </div>
            </div>
            <hr className="border-black border-2 mt-2" />

            <table className="table-auto w-full border-collapse mt-8 text-sm">
                <thead className="table-header-group">
                    <tr>
                        <th>ID</th>
                        <th className="whitespace-nowrap">URL</th>
                        <th>Acccount</th>
                        <th>Alerts Status</th>
                        <th>Scrapper Status</th>
                        <th>Skin</th>
                        <th>Created At</th>
                        {/* <th>Action</th> */}
                    </tr>
                </thead>
                <tbody>
                    {accounts?.map((item, index) => {
                        return (
                            <tr
                                key={index}
                                className={`${editMode == index && "bg-gray-300"
                                    } rounded-md text-center border border-b-1 border-t-0 border-r-0 border-l-0 py-4 cursor-pointer hover:bg-gray-200`}
                                onDoubleClick={() =>
                                    activateEditMode(
                                        item?.value,
                                        item?.is_active,
                                        item?.key,
                                        index
                                    )
                                }
                            >
                                <td className="overflow-hidden py-4">{item?.id}</td>
                                <td className="overflow-hidden py-4">{item?.url}</td>
                                <td className="overflow-hidden py-4">{item?.account}</td>
                                <td className="overflow-hidden py-4">
                                    <ActiveStatus
                                        value={item?.active_for_telegram}
                                        index={index}
                                        active_status={activeStatus}
                                        editMode={editMode}
                                        setActiveStatus={setActiveStatus}
                                        activeStatus={activeStatus}
                                    />
                                </td>
                                <td className="">
                                    <ActiveStatus
                                        value={item?.active_for_scrapper}
                                        index={index}
                                        active_status={activeStatus}
                                        editMode={editMode}
                                        setActiveStatus={setActiveStatus}
                                        activeStatus={activeStatus}
                                    />
                                </td>
                                <td className="overflow-hidden py-4">
                                    {item?.skin}
                                </td>
                                <td className="overflow-hidden py-4">
                                    {moment(item?.created_at).format("LLLL")}
                                </td>
                                {editMode == index && (
                                    <td className="flex justify-end items-center gap-2 p-2">
                                        <button
                                            onClick={updateHandicapper}
                                            className="bg-green-400 text-green-800 py-2 px-4 rounded"
                                        >
                                            {/* <FontAwesomeIcon icon={faCheck} /> */}
                                            Save
                                        </button>
                                        <button
                                            onClick={() => setEditMode(null)}
                                            className="bg-gray-400 text-gray-800 py-2 px-4 rounded"
                                        >
                                            {/* <FontAwesomeIcon icon={faXmark} /> */}
                                            Cancel
                                        </button>
                                    </td>
                                )}
                                {/* <td>
                                    <button
                                        onClick={() => navigate(`capper-details/${item?.key}`)}
                                        className="bg-orange-400 text-orange-800 py-2 px-4 mb-2 rounded"
                                    >
                                        <FontAwesomeIcon icon={faEye} />
                                    </button>
                                </td> */}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {showAddModal && (
                <Modal title="Add Account" closeModal={() => setAddModal(false)}>
                    <AddAccount closeModal={() => setAddModal(false)} />
                </Modal>
            )}
        </>
    );
}

const ActiveStatus = (props) => {
    return (
        <>
            {props.editMode == props.index ? (
                <>
                    <span
                        className={`${props.activeStatus
                                ? "bg-green-400 text-green-900"
                                : "bg-red-400 text-red-900"
                            } py-2 px-8 rounded-lg transition-all `}
                    >
                        {props.activeStatus ? "Active" : "Inactive"}
                    </span>
                    <FontAwesomeIcon
                        onClick={() => props.setActiveStatus(!props.activeStatus)}
                        className="px-2 hover:text-lg w-8 transition-all"
                        size="1x"
                        icon={faRepeat}
                    />
                </>
            ) : (
                <span
                    className={`${props?.value
                            ? "bg-green-400 text-green-900"
                            : "bg-red-400 text-red-900"
                        } py-2 px-4 rounded-lg transition-all`}
                >
                    {props?.value ? "Active" : "Inactive"}
                </span>
            )}
        </>
    );
};

export default Accounts;
