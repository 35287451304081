import React, { useRef, useState } from "react";
import { saveHandicapper } from "../../../routes/api";

function AddAccount({ closeModal }) {
  const formRef = useRef(null);
  const [errors, setErrors] = useState([]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      let request = {};
      for (let [key, value] of Array.from(formData.entries())) {
        request[key] = value;
      }
      saveHandicapper(request).then((res) => {
        if (res?.data?.status == "failure") setErrors(res?.data?.errors);
        else closeModal();
      });
    }
  };

  return (
    <form class="p-4 md:p-5" ref={formRef} onSubmit={handleSubmit}>
      <div class="grid gap-4 mb-4 grid-cols-2">
        <div class="col-span-1">
          <label
            for="name"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            URL
          </label>
          <input
            type="text"
            name="title"
            id="name"
            readOnly
            class="bg-gray-50 border border-gray-300 text-gray-900  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
            placeholder="Type handicapper name"
            required=""
          />
          {errors.title && (
            <p className="bg-red-400 text-red-800 p-1 mt-2 rounded">
              {errors.title[0]}
            </p>
          )}
        </div>
        <div class="col-span-2 sm:col-span-1">
          <label
            for="category"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Telegram Alert Status
          </label>
          <select
            name="is_active"
            id="category"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
          >
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </select>
        </div>
        <div class="col-span-2 sm:col-span-1">
          <label
            for="category"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Scrapper Status
          </label>
          <select
            name="is_active"
            id="category"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
          >
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </select>
        </div>
      </div>
      <div className="w-full flex justify-end">
        <button
          type="submit"
          class="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          <svg
            class="me-1 -ms-1 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
          Add new account
        </button>
      </div>
    </form>
  );
}

export default AddAccount;
