import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { authSidebarOptions } from "../utils/options";
import { useEffect, useState } from "react";
import { getActiveHandicappers } from "../routes/api";
import { setHandicappers } from "../redux/slices/handicappers";
import { useDispatch } from "react-redux";

export default function Dashboard() {
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("dashboard");
    getActiveHandicappers().then((res) => {
      dispatch(setHandicappers(res?.data));
    });
  }, []);

  const [isExpanded, setIsExpanded] = useState(true);

  const handleIsExpanded = () => setIsExpanded(!isExpanded);

  return (
    <>
      <div className="flex transition">
        <div className={`relative`}>
          <Sidebar
            options={authSidebarOptions}
            isExpanded={isExpanded}
            handleIsExpanded={handleIsExpanded}
          />
        </div>
        <div className={`w-full p-10`}>
          <Outlet />
        </div>
      </div>
    </>
  );
}
