import { useEffect, useMemo, useRef, useState } from "react";
import {
  getAccounts,
  getActiveHandicappers,
  getDailyFigures,
  getUserNames,
  updateDailyFigure,
} from "../../../routes/api";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DropDown } from "../../../components/form/DropDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPenToSquare,
  faEye,
  faArrowRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import { searchAction } from "../../../redux/slices/search";

import {
  formatResult,
  getStatuses,
  getWeeks,
  resultColor,
} from "../../../utils/helpers";
import moment from "moment";
import { toast } from "react-toastify";
import Loader from "../../../components/mix/Loader";
import Calendar from "../../../components/form/Calendar";
import CalendarButton from "../../../components/form/CalendarButton";

export default function DailyFigures() {
  const dispatch = useDispatch();

  const [selectedWeek, setSelectedWeek] = useState(0);
  const [accounts, setAccounts] = useState([]);
  const [resultLoader, setResultLoader] = useState({
    id: 0,
    loading: false,
  });

  const [usernames, setUsernames] = useState([]);

  //calendar buttons states

  const weeks = getWeeks();
  const statuses = getStatuses();

  // const [handicappers, setHandicappers] = useState([]);

  const {handicappers} = useSelector(state => state.handicappers)
  const [isLoading, setIsLoading] = useState(true);
  const [figures, setFigures] = useState([]);

  const filters = useSelector((state) => state.search);
  const start_date_input = useRef(null);
  const end_date_input = useRef(null);

  if (start_date_input.current && filters.start_date)
    start_date_input.current.value = filters.start_date;
  if (end_date_input.current && filters.end_date)
    end_date_input.current.value = filters.end_date;

  useEffect(() => {
    getFigures(filters);
    // getActiveHandicappers().then((res) => {
    //   setHandicappers(res.data);
    // });
    getAccounts().then((res) => {
      setAccounts(res.data.data);
    });
    getUserNames().then((res) => {
      setUsernames(res.data.data);
    });
  }, []);

  async function getFigures(params) {
    return getDailyFigures({ ...params, type: "daily" }).then((res) => {
      setFigures(res?.data?.data);
      setIsLoading(false);
      return true;
    });
  }

  const handleFilterSubmit = () => {
    setIsLoading(true);
    getFigures({ ...filters });
  };

  const handlePagination = (page) => {
    setIsLoading(true);
    getFigures({ page: page, ...filters });
  };

  const handleStatusChange = async (e, id) => {
    let resultLoad = {
      id: id,
      loading: true,
    };
    setResultLoader(resultLoad);
    let value = e.target.value;
    const data = {
      status: value,
    };
    const res = await updateDailyFigure(id, data);
    if (res?.data?.status == "success") {
      let result = await getFigures(filters);
      if (result) {
        let resultLoad = {
          id: 0,
          loading: false,
        };
        setResultLoader(resultLoad);
      }
      toast.success("Status Changed Successfully");
      return true;
    }
    return true;
  };

  const handleDateRange = async (e) => {
    setIsLoading(true);
    let end_date = moment(e.selection.endDate).format("YYYY-MM-DD");
    let start_date = moment(e.selection.startDate).format("YYYY-MM-DD");
    dispatch(searchAction({ start_date, end_date }));
    getFigures({ start_date, end_date });
  };

  const handleWeekChange = async (week) => {
    setSelectedWeek(week);
    week = parseInt(week);
    setIsLoading(true);

    let start_date = moment().subtract(week,'weeks').startOf("isoWeek").format("YYYY-MM-DD");
    let end_date = moment().subtract(week,'weeks').endOf('isoWeek').format("YYYY-MM-DD");

    dispatch(searchAction({ start_date, end_date }));
    getFigures({ ...filters, start_date, end_date });
    return true;
  };

  const handleHandicapperChange = async (e, id) => {
    let value = e.target.value;
    const data = {
      handicapper: value,
    };
    const res = await updateDailyFigure(id, data);
    if (res?.data?.status == "success") {
      toast.success("Handicapper Changed Successfully");
      return true;
    }
    return true;
  };

  const handleClearFilter = () => {
    setIsLoading(true);

    dispatch(
      searchAction({
        website: "",
        account_id: "",
        handicapper: "",
        // start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
        // end_date: moment().format("YYYY-MM-DD"),
      })
    );
    getFigures({
      website: "",
      account_id: "",
      handicapper: "",
      ...filters,
    });
  };

  const memofigures = useMemo(() => figures, [figures]);

  return (
    <div>
      <div className="grid grid-cols-10 gap-1 items-center">
        <div className="col-span-1 ">
          <DropDown
            value={filters.website}
            value_is_key={true}
            showFirstOption={true}
            handleChange={(e) => {
              dispatch(searchAction({ ...filters, website: e.target.value }));
              return true;
            }}
            label="Select Website"
            showlabel="false"
            name="account"
            options={accounts}
          />
        </div>
        <div className="col-span-1">
          <DropDown
            value={filters.account_id}
            value_is_key={true}
            showFirstOption={true}
            handleChange={(e) => {
              dispatch(
                searchAction({ ...filters, account_id: e.target.value })
              );
              return true;
            }}
            label="Select Account"
            name="account_id"
            options={usernames}
          />
        </div>
        <div className="col-span-1">
          <DropDown
            value={filters.handicapper}
            value_is_key={true}
            showFirstOption={true}
            handleChange={(e) => {
              dispatch(
                searchAction({ ...filters, handicapper: e.target.value })
              );
              return true;
            }}
            label="Handicapper"
            name="handicapper"
            options={handicappers}
          />
        </div>
        <div className="col-span-1 flex justify-center">
          <button
            onClick={handleFilterSubmit}
            className="bg-primary px-2 py-1 mr-2 text-white rounded"
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
          <button
            onClick={handleClearFilter}
            className="bg-primary px-2 py-1 text-white rounded"
          >
            <FontAwesomeIcon icon={faArrowRotateRight} />
          </button>
        </div>
        <div className="col-span-6 flex justify-end gap-2">
          <CalendarButton
            btnText={"This week"}
            handleSubmit={() => handleWeekChange(0)}
            selected={selectedWeek == 0}
          />
          <CalendarButton
            btnText={"Last week"}
            handleSubmit={() => handleWeekChange(1)}
            selected={selectedWeek == 1}
          />
          <CalendarButton
            btnText={"Two weeks ago"}
            handleSubmit={() => handleWeekChange(2)}
            selected={selectedWeek == 2}
          />
          <DropDown
            value={selectedWeek}
            value_is_key={false}
            showFirstOption={true}
            handleChange={(e) => handleWeekChange(e.target.value)}
            label="Select Week"
            showlabel="false"
            name="account"
            options={weeks}
          />
          <Calendar onSelect={handleDateRange} />
        </div>
        {/* <div className="w-full">
          <input
            type="date"
            onChange={(e) =>
              dispatch(searchAction({ ...filters, start_date: e.target.value }))
            }
            className="border w-full text-sm border-gray-300 p-2 rounded-md"
            placeholder="Start Date"
          />
        </div>
        <div className="w-full">
          <input
            type="date"
            onChange={(e) =>
              dispatch(searchAction({ ...filters, end_date: e.target.value }))
            }
            className="border w-full text-sm border-gray-300 p-2 rounded-md"
            placeholder="End Date"
          />
        </div> */}
      </div>
      <hr className="border-black border-2 mt-2" />
      {!isLoading ? (
        <>
          <table className="table-auto w-full border-collapse mt-4 text-sm">
            <thead>
              <tr>
                <th>Website</th>
                <th className="whitespace-nowrap">Account ID</th>
                <th>Handicapper</th>
                <th>Team</th>
                <th>Spread</th>
                <th>Odds</th>
                <th>Total</th>
                <th>Result</th>
                <th>Status</th>
                <th>League</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {memofigures?.data?.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className="overflow-hidden whitespace-nowrap text-center border border-b-1 border-t-0 border-r-0 border-l-0"
                  >
                    <td className="overflow-hidden p-2">
                      {item?.website?.slice(8)}
                    </td>
                    <td className="overflow-hidden p-2">{item?.account_id}</td>
                    <td className="overflow-hidden p-2">
                      <DropDown
                        value={item?.handicapper}
                        container_classes="w-full flex flex-col"
                        value_is_key={true}
                        handleChange={(e) =>
                          handleHandicapperChange(e, item.id)
                        }
                        label="Select"
                        showFirstOption={true}
                        name="handicapper"
                        options={handicappers}
                      />
                    </td>
                    <td className="overflow-hidden p-2">
                      <span style={{ whiteSpace: "pre-wrap" }}>
                        {item?.team_bet_on}
                      </span>
                    </td>
                    <td className="overflow-hidden p-2">
                      {item?.spread == "N/A" ? "" : item?.spread}
                    </td>
                    <td className="overflow-hidden p-2">
                      {item?.odds == "N/A" ? "" : item?.odds}
                    </td>
                    <td className="overflow-hidden p-2 ">
                      {item?.total == "N/A" || item?.total == "N\\A"
                        ? ""
                        : item?.total}
                    </td>
                    <td
                      className={`overflow-hidden p-2 font-bold text-right ${resultColor(
                        item
                      )}`}
                    >
                      {resultLoader.id == item?.id && resultLoader.loading ? (
                        <Loader />
                      ) : (
                        formatResult(item)
                      )}
                    </td>
                    <td className="overflow-hidden p-2">
                      <DropDown
                        value={item?.status}
                        container_classes="w-full"
                        value_is_key={true}
                        handleChange={(e) => handleStatusChange(e, item.id)}
                        label=""
                        name="status"
                        options={statuses}
                      />
                    </td>
                    <td className="overflow-hidden p-2 text-sm">
                      {item?.sport}
                    </td>
                    <td className="overflow-hidden p-2">
                      {item?.time?.endsWith("AM") || item?.time?.endsWith("PM")
                        ? item?.date + " " + item?.time
                        : moment(`${item?.date} ${item?.time}`)?.format(
                            "YYYY-MM-DD hh:mm A"
                          )}
                    </td>
                    <td className="overflow-hidden p-2">
                      <Link
                        to={`/auth/dashboard/single-figure/${item.id}`}
                        type="button"
                        className="inline-flex items-center mr-2  rounded-md bg-orange-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </Link>
                      <Link
                        to={`/auth/dashboard/edit-figure/${item.id}`}
                        type="button"
                        className="inline-flex  mr-2 items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* <div className="flex gap-1 mt-2">
            {figures?.links?.map((item, index) => {
              let label = item.label;
              let page = item?.url?.split("?")[1];
              let page_numer = page?.split("=")[1];
              if (label.includes("Previous"))
                return (
                  <div
                    onClick={() => handlePagination(page_numer)}
                    className={`p-2 border cursor-pointer 
                            ${
                              item.active
                                ? "bg-primary text-white"
                                : "hover:bg-gray-400"
                            }
                            ${item.url == null && "disabled"}`}
                    key={index}
                  >
                    {label.split(" ")[1]}
                  </div>
                );
              else if (label.includes("Next"))
                return (
                  <div
                    onClick={() => handlePagination(page_numer)}
                    className={`p-2 border cursor-pointer 
                            ${
                              item.active
                                ? "bg-primary text-white"
                                : "hover:bg-gray-400"
                            }
                            ${!item.url && "disabled"}`}
                    key={index}
                  >
                    {label.split(" ")[0]}
                  </div>
                );
              else
                return (
                  <div
                    onClick={() => handlePagination(page_numer)}
                    className={`px-4 py-2 border cursor-pointer 
                        ${
                          item?.active
                            ? "bg-primary text-white "
                            : "hover:bg-gray-400"
                        }`}
                    key={index}
                   >
                    {label}
                  </div>
                );
            })}
          </div> */}
        </>
      ) : (
        <table className="table-auto  rounded w-full border-collapse mt-8 text-sm">
          <tbody>
            <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
              <td className="rounded-md"></td>
            </tr>
            <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
              <td className="rounded-md"></td>
            </tr>
            <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
              <td className="rounded-md"></td>
            </tr>
            <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
              <td className="rounded-md"></td>
            </tr>
            <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
              <td className="rounded-md"></td>
            </tr>
            <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
              <td className="rounded-md"></td>
            </tr>
            <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
              <td className="rounded-md"></td>
            </tr>
            <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
              <td className="rounded-md"></td>
            </tr>
            <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
              <td className="rounded-md"></td>
            </tr>
            <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
              <td className="rounded-md"></td>
            </tr>
            <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
              <td className="rounded-md"></td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}
