import {
  faChartColumn,
  // faTableColumns,
  faMagic,
  faAdd,
  faSignOut,
  faUsers,
  faLock,
  faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import { setIsLoggedIn, setToken } from "../redux/slices/auth";

export const authSidebarOptions = [
  // {
  //   icon: faTableColumns,
  //   title: "Dashboard",
  //   url: "dashboard",
  // },
  {
    icon: faChartColumn,
    title: "Figures",
    url: "daily-figures",
  },
  {
    icon: faFileInvoice,
    title: "Accounts",
    url: "accounts",
  },
  {
    icon: faUsers,
    title: "Cappers",
    url: "handicappers",
  },
  {
    icon: faAdd,
    title: "Add New Bet",
    url: "add-bet",
    action: null,
  },
  {
    icon: faSignOut,
    title: "Logout",
    url: "logout",
    action: (dispatch) => {
      localStorage.removeItem("token");
      localStorage.removeItem("isLoggedIn");
      dispatch(setToken(null));
      dispatch(setIsLoggedIn(false));
      window.location.reload();
    },
  },
];
