import { configureStore } from "@reduxjs/toolkit";
import search from "./slices/search";
import auth from "./slices/auth";
import handicappers from "./slices/handicappers";

const store = configureStore({
  reducer: {
    search: search,
    auth: auth,
    handicappers,
  },
});

export default store;
