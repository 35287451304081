import React from 'react';
import { useField, useFormikContext } from 'formik';
import Creatable from 'react-select/creatable';

// Custom hook to use Formik with react-select
const CreatableSelect = ({ options, name, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (option) => {
    setFieldValue(name, option ? option.value : '');
  };

  return (
    <>
      <Creatable
        {...props}
        value={field.value ? { value: field.value, label: field.value } : null}
        onChange={handleChange}
        options={options}
        placeholder="Select or create..."
      />
    </>
  );
};

export default CreatableSelect;
