import "./App.css";
import { useEffect } from "react";
import Authentication from "./components/Authentication";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from "./pages/login";
import Layout from "./pages/Layout";
import Dashboard from "./pages/Dashboard";
import DailyFigures from "./pages/auth-pages/figures/DailyFigures";
import PendingFigures from "./pages/auth-pages/figures/PendingFigures";
import { getCsrf } from "./routes/api";
import EditFigure from "./pages/auth-pages/figures/EditFigure";
import SingleFigure from "./pages/auth-pages/figures/SingleFigure";
import AddBet from "./pages/auth-pages/figures/AddBet";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { setIsLoggedIn, setToken } from "./redux/slices/auth";
import Handicappers from "./pages/auth-pages/handicappers/Handicappers";
import HandicapperDetail from "./pages/auth-pages/handicappers/HandicapperDetail";
import Insights from "./pages/auth-pages/handicappers/Insights";
import Accounts from "./pages/auth-pages/accounts/Accounts";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const token = localStorage.getItem("token");

  useEffect(() => {
    const abortController = new AbortController();
    getCsrf();
    // if (!isLoggedIn) {
    dispatch(setToken(token));
    dispatch(setIsLoggedIn(isLoggedIn));
    let path =
      location.pathname == "/"
        ? "/auth/dashboard/daily-figures"
        : location.pathname;
    navigate(path, { state: location, replace: true });
    // }

    return () => abortController.abort();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="login" element={<Login />} />
        <Route path="auth" element={<Authentication />}>
          <Route path="dashboard" element={<Dashboard />}>
            <Route path="daily-figures" element={<DailyFigures />} />
            <Route path="dashboard" element={<DailyFigures />} />
            <Route path="pending-figures" element={<PendingFigures />} />
            <Route path="edit-figure/:id" element={<EditFigure />} />
            <Route path="single-figure/:id" element={<SingleFigure />} />
            <Route path="handicappers">
              <Route path="" element={<Handicappers />} />
              <Route path="insights" element={<Insights />} />
              <Route
                path="capper-details/:id"
                element={<HandicapperDetail />}
              />
            </Route>
            <Route path="accounts">
              <Route path="" element={<Accounts/>}/>
            </Route>
            <Route path="add-bet" element={<AddBet />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
