import React, { useEffect } from "react";

export default function TableChart({ xAxisHeaders, yAxisHeaders, data }) {
  let chartData = [];

  useEffect(() => {
    chartData = xAxisHeaders.map((item, index) => {
      let temp = {};
      temp.handicapper = item.value
    });

    data && Object.keys(data).map((item, index) => console.log(item));
  }, []);

  return (
    <div className="mt-2">
      <table className="table-auto w-full border border-gray-700">
        {/* <tr>
          <td></td>
          {xAxisHeaders.map((item, index) => (
            <td key={index} className="border border-gray-700 text-center">
              {item?.value}
            </td>
          ))}
        </tr> */}
        {data &&
          Object.keys(data).map((week, index) => (
            <tr>
              <td key={index} className="w-2/12 border border-gray-700 text-center">
                {week}
              </td>
              <td className="border border-gray-700 text-center">
                {data[week] && data[week].map((handicapper) => (

                  handicapper.handicapper_name && `${handicapper.handicapper_name}:${handicapper.count} - `
                ))}
                Others : 0
              </td>
              {/* {xAxisHeaders.map((handicapper, index) => {
                return (
                  <RenderCounts
                    handicapper={handicapper}
                    data={data}
                    week={week}
                  />
                );
              })} */}
            </tr>
          ))}
      </table>
    </div>
  );
}

function RenderCounts({ handicapper, data, week }) {
  console.log(handicapper, data, week);
  debugger
  return (
    <>
      {data[week].map((item, index) => {
        if (item.handicapper_name)
          return (
            <td key={index} className="border border-gray-700 text-center">
              {handicapper.value == item?.handicapper_name && item?.handicapper_name}
            </td>
          );
        else
          return (
            <td key={index} className="border border-gray-700 text-center">
              0
            </td>
          );
      })}
    </>
  );
}
