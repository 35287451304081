import React, { Ref, forwardRef } from "react";

const InputLabel = forwardRef((props, ref) => {
  return (
    <div className={props.container_classes}>
      <label
        htmlFor="about"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {props.label_title}
      </label>
      <div className="flex rounded-md shadow-sm ring-1 ring-gray-300 sm:max-w-md">
        <input
          type={props.input_type}
          name={props.input_name}
          id={props.input_id}
          className="block flex-1 border-0 p-2 bg-transparent text-gray-900 placeholder:text-gray-400  sm:text-sm "
          defaultValue={props?.value}
          ref={ref}
        />
      </div>
    </div>
  );
});

export default InputLabel;
