import { createSlice } from "@reduxjs/toolkit";
import moment from 'moment'

export const searchSlice  = createSlice({
    name:'search-filter',
    initialState:{
        website:null,
        account_id:null,
        handicapper:null,
        start_date:moment().subtract(0,'weeks').startOf("isoWeek").format("YYYY-MM-DD"),
        end_date:moment().subtract(0,'weeks').endOf('isoWeek').format("YYYY-MM-DD"),
    },
    reducers:{
        searchAction:(state,action) => {
            state.website = action.payload.website ?? state.website
            state.account_id = action.payload.account_id ?? state.account_id
            state.start_date = action.payload.start_date ?? state.start_date
            state.end_date = action.payload.end_date ?? state.end_date
            state.handicapper = action.payload.handicapper ?? state.handicapper
        }
    }
})

export const { searchAction } = searchSlice.actions

export default searchSlice.reducer