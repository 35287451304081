import React, { useEffect, useState } from "react";
import { getHandicappers, updateHandicapper } from "../../../routes/api";
import AddHandicappers from "./AddHandicappers";
import Modal from "../../../components/Modal";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faCheck,
  faEye,
  faPlus,
  faRepeat,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useTransition } from "react";
import { useNavigate } from "react-router-dom";

function Handicappers() {
  const [handicappers, setHandicappers] = useState([]);
  const [showAddModal, setAddModal] = useState(false);
  const [editMode, setEditMode] = useState(-1);
  const [activeStatus, setActiveStatus] = useState(null);
  const [updateId, setUpdateId] = useState(null);
  const [status, setStatus] = useState("1");
  const [handicapperName, setHandicapperName] = useState(null);
  const [isPending, startTransition] = useTransition();

  const navigate = useNavigate();

  useEffect(() => {
    fetchHandicappers(1);
  }, [showAddModal]);

  const activateEditMode = (name, active_status, id, index) => {
    setHandicapperName(name);
    setActiveStatus(active_status);
    setUpdateId(id);
    setEditMode(index);
  };

  const updateHandicapper = () => {
    updateHandicapper(
      { title: handicapperName, is_active: activeStatus },
      updateId
    ).then((res) => {
      if (res?.data?.status == "success") {
        setEditMode(-1);
        startTransition(() => {
          fetchHandicappers(status);
        });
      }
    });
  };

  const fetchHandicappers = (status) => {
    setStatus(status);
    getHandicappers(status).then((res) => {
      setHandicappers(res?.data);
    });
  };

  return (
    <>
      <div className="flex justify-between items-end">
        <h1 className="text-lg font-bold">Handicappers</h1>
        <div>
          <button
            onClick={() => setAddModal(true)}
            className="bg-primary text-white rounded p-2 mr-2 text-bold"
          >
            <FontAwesomeIcon className="mr-1" icon={faPlus} />
            Add Handicapper
          </button>
          <button
            onClick={() => navigate('insights')}
            className="bg-gray-500 text-white rounded p-2 text-bold"
          >
            <FontAwesomeIcon className="mr-1" icon={faChartLine} />
            Insights
          </button>
        </div>
      </div>
      <hr className="border-black border-2 mt-2" />
      <div className="flex justify-between  text-center bg-gray-50">
        <button
          onClick={() => fetchHandicappers(1)}
          disabled={status == "1"}
          className={`${
            status == "1"
              ? "bg-orange-400 text-white"
              : "text-black hover:bg-orange-500"
          } w-6/12 p-2 font-bold cursor-pointer `}
        >
          Active
        </button>
        <button
          onClick={() => fetchHandicappers(0)}
          disabled={status == "0"}
          className={`${
            status == "0"
              ? "bg-orange-400 text-white"
              : "text-black  hover:bg-orange-500"
          } w-6/12 p-2 font-bold cursor-pointer`}
        >
          In Active
        </button>
      </div>
      <table className="table-auto w-full border-collapse mt-8 text-sm">
        <thead className="table-header-group">
          <tr>
            <th>ID</th>
            <th className="whitespace-nowrap">Name</th>
            <th>Active Status</th>
            <th>Created At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {handicappers?.map((item, index) => {
            return (
              <tr
                key={index}
                className={`${
                  editMode == index && "bg-gray-300"
                } rounded-md text-center border border-b-1 border-t-0 border-r-0 border-l-0 py-4 cursor-pointer hover:bg-gray-200`}
                onDoubleClick={() =>
                  activateEditMode(
                    item?.value,
                    item?.is_active,
                    item?.key,
                    index
                  )
                }
              >
                <td className="overflow-hidden py-4">{item?.key}</td>
                <td className="overflow-hidden py-4">
                  {editMode == index ? (
                    <input
                      value={handicapperName}
                      className="px-1 py-1 rounded"
                      onChange={(e) => setHandicapperName(e.target.value)}
                    />
                  ) : (
                    item?.value
                  )}
                </td>
                <td className="">
                  <ActiveStatus
                    value={item?.is_active}
                    index={index}
                    active_status={activeStatus}
                    editMode={editMode}
                    setActiveStatus={setActiveStatus}
                    activeStatus={activeStatus}
                  />
                </td>
                <td className="overflow-hidden py-4">
                  {moment(item?.created_at).format("LLLL")}
                </td>
                {editMode == index && (
                  <td className="flex justify-end items-center gap-2 p-2">
                    <button
                      onClick={updateHandicapper}
                      className="bg-green-400 text-green-800 py-2 px-4 rounded"
                    >
                      {/* <FontAwesomeIcon icon={faCheck} /> */}
                      Save
                    </button>
                    <button
                      onClick={() => setEditMode(null)}
                      className="bg-gray-400 text-gray-800 py-2 px-4 rounded"
                    >
                      {/* <FontAwesomeIcon icon={faXmark} /> */}
                      Cancel
                    </button>
                  </td>
                )}
                <td>
                  <button
                    onClick={() => navigate(`capper-details/${item?.key}`)}
                    className="bg-orange-400 text-orange-800 py-2 px-4 mb-2 rounded"
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {showAddModal && (
        <Modal title="Add Handicapper" closeModal={() => setAddModal(false)}>
          <AddHandicappers closeModal={() => setAddModal(false)} />
        </Modal>
      )}
    </>
  );
}

const ActiveStatus = (props) => {
  return (
    <>
      {props.editMode == props.index ? (
        <>
          <span
            className={`${
              props.activeStatus
                ? "bg-green-400 text-green-900"
                : "bg-red-400 text-red-900"
            } py-2 px-8 rounded-lg transition-all `}
          >
            {props.activeStatus ? "Active" : "Inactive"}
          </span>
          <FontAwesomeIcon
            onClick={() => props.setActiveStatus(!props.activeStatus)}
            className="px-2 hover:text-lg w-8 transition-all"
            size="1x"
            icon={faRepeat}
          />
        </>
      ) : (
        <span
          className={`${
            props?.value
              ? "bg-green-400 text-green-900"
              : "bg-red-400 text-red-900"
          } py-2 px-4 rounded-lg transition-all`}
        >
          {props?.value ? "Active" : "Inactive"}
        </span>
      )}
    </>
  );
};

export default Handicappers;
