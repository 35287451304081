import React from "react";

export default function HandicapperDetail() {
  return (
    <div>
      <div>
        <h1 className="text-lg font-bold">Handicappers</h1>
      </div>
      <hr className="border-black border-2 mt-2" />
    </div>
  );
}
