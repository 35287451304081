import { useEffect, useState } from "react";
import { getDailyFigureById } from "../../../routes/api";
import { useNavigate, useParams } from "react-router-dom";

export default function SingleFigure() {

    const params = useParams()
    const [data, setData] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        getDailyFigureById(params.id).then(res => {
            setData(res.data.data)
        })
    }, [])

  return (
    <div>
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Scrapper Data</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">This information is scrapped from account using scrapper.</p>
      </div>
      <div className="mt-6">
        <dl className="grid grid-cols-1 sm:grid-cols-2">
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Website</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.website}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Account ID</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.account_id}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Booking Ticket Id</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.book_ticket_id}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Game No</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.game_no}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Team 1</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.team_1}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Team 2</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.team_2}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Bet Type</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.bet_type}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Odds</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.odds}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Spread</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.spread}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Team Bet On</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.team_bet_on}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Risk</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.risk}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Win</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.win}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Status</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.status}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Final Score</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.final_score}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Accepted</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.accepted}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Placed On</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.placed_on}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Sport</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.sport}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Period</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.period}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Date</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.date}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Time</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.time}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Timezone</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.timezone}</dd>
          </div>
        </dl>
      </div>
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Custom Data</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">This information added manually.</p>
      </div>
      <div className="mt-6">
        <dl className="grid grid-cols-1 sm:grid-cols-2">
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Master Bet ID</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.master_bet_id ?? "Not set"}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Worker</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.worker ?? "Not set"}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Worker free roll</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.worker_freeroll ?? "Not set"}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Handicapper</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.handicapper ?? "Not set"}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Handicapper Risk</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.handicapper_risk ?? "Not set"}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Play Type</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.play_type ?? "Not set"}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Agent </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.agent_id ?? "Not set"}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Master Agent Risk</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.master_agent_risk ?? "Not set"}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Sub Agent Risk</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.sub_agent_risk ?? "Not set"}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Master Agent Freeroll</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.master_agent_freeroll ?? "Not set"}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Sub Agent Freeroll</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.sub_agent_freeroll ?? "Not set"}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Notes</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.notes ?? "Not set"}</dd>
          </div>
        </dl>
      </div>
    </div>
  )
}
