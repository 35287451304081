import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import CreatableSelect from "../../../components/form/CreatableSelect";
import { toast } from 'react-toastify';
import { getTeams } from "../../../utils/helpers";
import { saveDailyFigure, getActiveHandicappers, fetchWebsites, fetchAccounts } from "../../../routes/api";
import { useNavigate } from "react-router-dom";

export default function AddBet() {
  const [handicappers, setHandicappers] = useState([]);
  const [websites, setWebsites] = useState([]);
  const [accounts, setAccounts] = useState([]);
  let teams = getTeams();
  const navigate = useNavigate();

  useEffect(() => {
    getActiveHandicappers().then((res) => {
      setHandicappers(res.data);
    });
    fetchWebsites().then((res) => {
      console.log('websites: ', res.data.data);
      setWebsites(res.data.data);
    });
    fetchAccounts().then((res) => {
      console.log('accounts: ', res.data.data);
      setAccounts(res.data.data);
    });
  }, []);

  const initialValues = {
      website: "",
      account_id: "",
      handicapper: "",
      team_1: "",
      team_2: "",
      team_bet_on: "",
      spread: "",
      total: "",
      odds: "",
      sport: "",
      date: "",
      book_ticket_id: "",
      game_no: "",
      bet_type: "",
      risk: "",
      win: "",
      status: "",
      final_score: "",
      accepted: "",
      placed_on: "",
      period: "",
      master_bet_id: "",
      worker: "",
      worker_freeroll: "",
      handicapper_risk: "",
      play_type: "",
      agent_id: "",
      sub_agent_id: "",
      book_id: "",
      master_agent_risk: "",
      sub_agent_risk: "",
      master_agent_freeroll: "",
      notes: "",
    };
    const validationSchema = Yup.object({
      website: Yup.string()
        .required("Please select the website"),
      account_id: Yup.string()
        .required("Please select the account"),
      handicapper: Yup.string()
        .required("Please select the handicapper name"),
      team_1: Yup.string()
        .required("Please select the team1"),
      team_2: Yup.string()
        .required("Please select the team2"),
      team_bet_on: Yup.string()
        .required("Please indicate which team you are betting on"),
      spread: Yup.string()
        .required("Please provide the spread"),
      total: Yup.string()
        .required("Total points or goals are required"),
      odds: Yup.string()
        .required("Odds information is required"),
      sport: Yup.string()
        .required("Please enter the sport name"),
      date: Yup.string()
        .required("Date is required"),
        book_ticket_id: Yup.string()
        .required("Booking Ticket Id is required"),
    });
    

    // Form submission handler
    const onSubmit = (values, { setSubmitting, resetForm }) => {
      console.log('form: ', values);
      
      saveDailyFigure(values).then((res) => {
        if (res.data.status === "success") {
          toast.success(res.data.message);
          // Clear the form
          resetForm();
        }
      }).catch((error) => {
        if (error.response && error.response.status === 422) {
          // Validation errors
          const validationErrors = error.response.data.errors;
          for (const [field, messages] of Object.entries(validationErrors)) {
            // Show error messages for each field
            // messages.forEach(message => toast.error(`${field}: ${message}`));
            messages.forEach(message => toast.error(`${message}`));
          }
        } else {
          // Other errors
          toast.error('An error occurred. Please try again.');
        }
       
      });;

      setSubmitting(false); // Reset isSubmitting to false after submission
    };
    

  return (
    <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
    {({ isSubmitting }) => (
    <Form>
    <h1 className="font-bold text-xl">Add New Bet</h1>
    <div className="grid grid-cols-3 gap-4 mt-4">
      
      <div className="col-span-1">
        <label htmlFor="website" className="form-label required">Website</label>
        <CreatableSelect name="website" options={websites}/>
        <ErrorMessage name="website" component="div" className="form-error" />
      </div>

      <div className="col-span-1">
      <label htmlFor="account_id" className="form-label required">Account</label>
        <CreatableSelect name="account_id" options={accounts}/>
        <ErrorMessage name="account_id" component="div" className="form-error" />
      </div>

      <div className="col-span-1">
        <label htmlFor="handicapper" className="form-label required">Handicapper</label>
        <Field
          as="select"
          name="handicapper"
          className="form-select"
        >
          <option value="">Select Handicapper</option>
          {handicappers.map((option) => (
            <option key={option.value} value={option.value}>
              {option.value}
            </option>
          ))}
        </Field>
        <ErrorMessage name="handicapper" component="div" className="form-error" />
      </div>
  
      <div className="col-span-1">
      <label htmlFor="team_1" className="form-label required">Team 1</label>
        <Field
          as="select"
          name="team_1"
          className="form-select"
        >
          <option value="">Select Team 1</option>
          {teams.map((option) => (
            <option key={option.value} value={option.value}>
              {option.value}
            </option>
          ))}
        </Field>
        <ErrorMessage name="team_1" component="div" className="form-error" />
      </div>
  
      <div className="col-span-1">
      <label htmlFor="team_2" className="form-label required">Team 2</label>
        <Field
          as="select"
          name="team_2"
          className="form-select"
        >
          <option value="">Select Team 2</option>
          {teams.map((option) => (
            <option key={option.value} value={option.value}>
              {option.value}
            </option>
          ))}
        </Field>
        <ErrorMessage name="team_2" component="div" className="form-error" />
      </div>
      
      <div className="col-span-1">
        <label htmlFor="team_bet_on" className="form-label required">Team Bet On</label>
        <Field
          type="text"
          name="team_bet_on"
          id="team_bet_on"
          className="form-control"
        />
        <ErrorMessage name="team_bet_on" component="div" className="form-error" />
      </div>
  
      <div className="col-span-1">
        <label htmlFor="spread" className="form-label required">Spread</label>
        <Field
          type="text"
          name="spread"
          id="spread"
          className="form-control"
        />
        <ErrorMessage name="spread" component="div" className="form-error" />
      </div>
  
      <div className="col-span-1">
        <label htmlFor="total" className="form-label required">Total</label>
        <Field
          type="text"
          name="total"
          id="total"
          className="form-control"
        />
        <ErrorMessage name="total" component="div" className="form-error" />
      </div>
  
      <div className="col-span-1">
        <label htmlFor="odds" className="form-label required">Odds</label>
        <Field
          type="text"
          name="odds"
          id="odds"
          className="form-control"
        />
        <ErrorMessage name="odds" component="div" className="form-error" />
      </div>
  
      <div className="col-span-1">
        <label htmlFor="sport" className="form-label required">Sport / League</label>
        <Field
          type="text"
          name="sport"
          id="sport"
          className="form-control"
        />
        <ErrorMessage name="sport" component="div" className="form-error" />
      </div>
  
      <div className="col-span-1">
        <label htmlFor="date" className="form-label required">Date and Time</label>
        <Field
          type="date"
          name="date"
          id="date"
          className="form-control"
        />
        <ErrorMessage name="date" component="div" className="form-error" />
      </div>
  
      <div className="col-span-1">
        <label htmlFor="book_ticket_id" className="form-label required">Booking Ticket Id</label>
        <Field
          type="text"
          name="book_ticket_id"
          id="book_ticket_id"
          className="form-control"
        />
        <ErrorMessage name="book_ticket_id" component="div" className="form-error" />
      </div>
  
      <div className="col-span-1">
        <label htmlFor="game_no" className="form-label">Game No</label>
        <Field
          type="text"
          name="game_no"
          id="game_no"
          className="form-control"
        />
      </div>
  
      <div className="col-span-1">
        <label htmlFor="bet_type" className="form-label">Bet Type</label>
        <Field
          type="text"
          name="bet_type"
          id="bet_type"
          className="form-control"
        />
      </div>
  
      <div className="col-span-1">
        <label htmlFor="risk" className="form-label">Risk</label>
        <Field
          type="text"
          name="risk"
          id="risk"
          className="form-control"
        />
      </div>
  
      <div className="col-span-1">
        <label htmlFor="win" className="form-label">Win</label>
        <Field
          type="text"
          name="win"
          id="win"
          className="form-control"
        />
      </div>
  
      <div className="col-span-1">
        <label htmlFor="status" className="form-label">Status</label>
        <Field
          type="text"
          name="status"
          id="status"
          className="form-control"
        />
      </div>
  
      <div className="col-span-1">
        <label htmlFor="final_score" className="form-label">Final Score</label>
        <Field
          type="text"
          name="final_score"
          id="final_score"
          className="form-control"
        />
      </div>
  
      <div className="col-span-1">
        <label htmlFor="accepted" className="form-label">Accepted At</label>
        <Field
          type="text"
          name="accepted"
          id="accepted"
          className="form-control"
        />
      </div>
  
      <div className="col-span-1">
        <label htmlFor="placed_on" className="form-label">Placed On</label>
        <Field
          type="text"
          name="placed_on"
          id="placed_on"
          className="form-control"
        />
      </div>
  
      
  
      <div className="col-span-1">
        <label htmlFor="period" className="form-label">Period</label>
        <Field
          type="text"
          name="period"
          id="period"
          className="form-control"
        />
      </div>
  
      <div className="col-span-1">
        <label htmlFor="master_bet_id" className="form-label">Master Bet ID</label>
        <Field
          type="text"
          name="master_bet_id"
          id="master_bet_id"
          className="form-control"
        />
      </div>
  
      <div className="col-span-1">
        <label htmlFor="worker" className="form-label">Worker</label>
        <Field
          type="text"
          name="worker"
          id="worker"
          className="form-control"
        />
      </div>
  
      <div className="col-span-1">
        <label htmlFor="worker_freeroll" className="form-label">Worker Freeroll</label>
        <Field
          type="text"
          name="worker_freeroll"
          id="worker_freeroll"
          className="form-control"
        />
      </div>

      <div className="col-span-1">
        <label htmlFor="handicapper_risk" className="form-label">Handicapper Risk</label>
        <Field
          type="text"
          name="handicapper_risk"
          id="handicapper_risk"
          className="form-control"
        />
      </div>

      <div className="col-span-1">
        <label htmlFor="play_type" className="form-label">Play Type</label>
        <Field
          type="text"
          name="play_type"
          id="play_type"
          className="form-control"
        />
      </div>

      <div className="col-span-1">
        <label htmlFor="agent_id" className="form-label">Agent</label>
        <Field
          type="text"
          name="agent_id"
          id="agent_id"
          className="form-control"
        />
      </div>

      <div className="col-span-1">
        <label htmlFor="sub_agent_id" className="form-label">Sub Agent</label>
        <Field
          type="text"
          name="sub_agent_id"
          id="sub_agent_id"
          className="form-control"
        />
      </div>

      <div className="col-span-1">
        <label htmlFor="book_id" className="form-label">Book Id</label>
        <Field
          type="text"
          name="book_id"
          id="book_id"
          className="form-control"
        />
      </div>


      <div className="col-span-1">
        <label htmlFor="master_agent_risk" className="form-label">Master Agent Risk</label>
        <Field
          type="text"
          name="master_agent_risk"
          id="master_agent_risk"
          className="form-control"
        />
      </div>

      <div className="col-span-1">
        <label htmlFor="sub_agent_risk" className="form-label">Sub Agent Risk</label>
        <Field
          type="text"
          name="sub_agent_risk"
          id="sub_agent_risk"
          className="form-control"
        />
      </div>

      <div className="col-span-1">
        <label htmlFor="master_agent_freeroll" className="form-label">Master Agent Free Roll</label>
        <Field
          type="text"
          name="master_agent_freeroll"
          id="master_agent_freeroll"
          className="form-control"
        />
      </div>

      <div className="col-span-1">
      <label
        htmlFor="notes"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Notes
      </label>
      <div className="flex rounded-md shadow-sm ring-1 ring-gray-300 sm:max-w-md">
        <Field
          as="textarea"
          name="notes"
          id="notes"
          className="block flex-1 border-0 p-2 bg-transparent text-gray-900 placeholder:text-gray-400 sm:text-sm"
        />
      </div>
    </div>

    </div>

    
  
    <button type="submit" className="btn-primary" disabled={isSubmitting}>
      Submit
    </button>
  </Form>
  
    )}

</Formik>
  );
}
